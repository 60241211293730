import React from 'react'

import TopHeader from '../../About/TopHeader'
import  aboutbanner  from '../../../assets/images/aboutbanner.jpg';
import aboutUss from '../../../assets/images/AboutUss.png'
import { Box, Typography, Grid, Card, Button, Container } from '@mui/material';
import Content from './Content';
import SingleService from './SingleService';
import VerticalTabs from './VerticalTabs';

// import ServicesDetails from './ServicesDetails';


const ServiceDetails = () => {
  return (
    <div>
    
      {/* <HorizontalScroll /> */}
      <TopHeader value="Services" backgroundImage={aboutUss}/>
      <Box>
        <Container>

        {/* <Grid container spacing={4} mt={4}>
          <Grid item xs={12} md={8}>
            <SingleService/>
          </Grid>
          <Grid item xs={12} md={4}  >
          
            <VerticalTabs/>
          </Grid>
          
        </Grid> */}


          <Box  sx={{
            alignItems: 'flex-start',
                height: '100vh', // Ensure parent has a height for sticky effect
                display: 'flex',
                flexDirection: 'column',
                
        }}>

            <Grid container spacing={6} mt={4}>
              <Grid item xs={12} md={8}>
                <SingleService/>
              </Grid>
              <Grid
                  item
                  xs={12}
                  md={4}

              >
                  <Box
                    sx={{
                      position: 'sticky',
                      top: 10, 
                      alignSelf: 'flex-start',
                      // mt: isSmallScreen ? 3 : 0,
                      textAlign: 'left',
                      height: 'fit-content', 
                      overflow: 'hidden',
                    }}
                  >
                  <VerticalTabs />
                  </Box>
                </Grid>
              
            </Grid> 
          </Box>




        </Container>
      </Box>
      
    </div>
  )
}

export default ServiceDetails
