import { createBrowserRouter } from "react-router-dom";
import { About, Blog, ContactUs, Landing, NotFound, Projects, Selector, Dashboard, ProjectDetails, ChannelPartner, GalleryGrid, BlogDetails, GalleryDetails, Careers } from '../Pages/Exports';
import Layout from '../Layout/Layout';
import OurServices from "../Pages/OurServices";
import ServiceDetails from "../Pages/OurServices/ServicesDetails";
import Calculator from "../Pages/Projects/Calculator";
import JobDetailsPage from "../Pages/Careers/JobDetailsPage";
import ApplicationPage from "../Pages/Careers/ApplicationPage";



const router = createBrowserRouter([

    {
      path: '/',
      element: <Landing />,
      errorElement: <NotFound />, 
    },
    {
      path : "/selector",
      element : <Selector />,
    },
    {
      element: <Layout />,
      children: [
        {
          path: "/home",
          element: <Dashboard />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/ourservices",
          element: <OurServices/>,
        },
        {
          path : "/ourservices/:id/:slug",
          element : <ServiceDetails />,
        },
        {
          //projects
          path: "/projects",
          element: <Projects />,
        },

        //projects/:id
        {
          path : "/projects/:id",
          element : <ProjectDetails />,
        },
        {
          path : "/channelpartner",
          element : <ChannelPartner />,
        },
        {
          path : "/gallery",
          element : <GalleryGrid />,
        },
        {
          path: '/careers',
          element: <Careers />,
        },
        {
          path: '/careers/:id',
          element: <JobDetailsPage />
        },
        {
          path: '/careers/apply/:id',
          element: <ApplicationPage />
        },
        {
          path : "/gallery/:id",
          element : <GalleryDetails />,
        },

        {
          path : "/blog",
          element : <Blog />,
        },
        {
          path:"/blog/:id",
          element:<BlogDetails/>
        },

        {
          path : "/contact",
          element : <ContactUs />,
        },
        {
          path : "/projects/:id/calculator",
          element : <Calculator />,
        },

        ],
    },
    {
      path : "/admin",
      element : <Dashboard />,
    },
  {
    path: "*",
    element: <NotFound />,
  },
]);




export default router;
