import React, { useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Chip,
  InputAdornment,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ShareIcon from "@mui/icons-material/Share";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { jobsData } from "./jobsData";
import { useNavigate } from "react-router-dom";

const CareerFinder: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [jobType, setJobType] = useState("");
  const [location, setLocation] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 6;
  const navigate = useNavigate();

  // Filtered Jobs
  const filteredJobs = jobsData.filter((job) => {
    const matchesSearch = job.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = jobType ? job.type === jobType : true;
    const matchesLocation = location ? job.location.toLowerCase().includes(location.toLowerCase()) : true;
    return matchesSearch && matchesType && matchesLocation;
  });

  // Pagination
  const totalJobs = filteredJobs.length;
  const totalPages = Math.ceil(totalJobs / itemsPerPage);
  const displayedJobs = filteredJobs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (_: any, page: number) => setCurrentPage(page);

  return (
    <Box>
      {/* Hero Banner */}
      <Box
        sx={{
          height: "300px",
          backgroundImage: `url("https://via.placeholder.com/1920x300")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" mb={2}>
          Find Your Dream Job
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          gap={2}
          justifyContent="center"
          sx={{ maxWidth: "800px", width: "100%" }}
        >
          <TextField
            placeholder="Search for Jobs..."
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "#fff" }} />
                </InputAdornment>
              ),
            }}
            sx={{ bgcolor: "#fff", borderRadius: "8px" }}
          />
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150, bgcolor: "#fff", borderRadius: "8px" }}>
            <InputLabel>Job Type</InputLabel>
            <Select
              value={jobType}
              onChange={(e) => setJobType(e.target.value)}
              IconComponent={ArrowDropDown}
              label="Job Type"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Full-time">Full-time</MenuItem>
              <MenuItem value="Part-time">Part-time</MenuItem>
              <MenuItem value="Contract">Contract</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" color="secondary" sx={{ borderRadius: "20px", height: "40px" }}>
            Search
          </Button>
        </Box>
        <Typography
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            fontSize: "1.2rem",
            backgroundColor: "#0008",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        >
          Total Jobs: {totalJobs}
        </Typography>
      </Box>

      {/* Job Listings */}
      <Box sx={{ padding: "20px" }}>
        <Grid container spacing={3}>
          {displayedJobs.map((job) => (
            <Grid item xs={12} sm={6} md={4} key={job.id}>
              <Card
                sx={{
                  boxShadow: 2,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* Card Part 1 */}
                <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box
                      sx={{
                        bgcolor: "#f0f0f0",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography variant="h6" fontWeight="bold">
                        {job.type[0]}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        IT & Networking
                      </Typography>
                      <Typography variant="body2">{job.title}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Expiring: {job.expiryDate}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <IconButton size="small">
                      <BookmarkBorderIcon />
                    </IconButton>
                    <IconButton size="small">
                      <ShareIcon />
                    </IconButton>
                  </Box>
                </CardContent>

                {/* Card Part 2 */}
                <Box display="flex" justifyContent="space-evenly" my={1}>
                  <Chip label="Temporary" size="small" />
                  <Chip label="Full-time" size="small" />
                  <Chip label="Urgent" size="small" color="error" />
                </Box>

                {/* Card Part 3 */}
                <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => navigate(`/careers/${job.id}`)}
                    sx={{ borderRadius: "20px" }}
                  >
                    Apply Now
                  </Button>
                  <Typography variant="caption" color="text.secondary">
                    Posted: {job.postedDaysAgo} days ago
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* No Results Found */}
        {filteredJobs.length === 0 && (
          <Typography variant="body1" color="text.secondary" textAlign="center" mt={4}>
            No jobs match your search criteria. Try adjusting your filters.
          </Typography>
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <Box mt={3} display="flex" justifyContent="center">
            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary" size="small" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CareerFinder;
