export const jobsData = [
  {
    id: "1",
    title: "Frontend Developer",
    company: "Tech Corp",
    experience: "2+ years",
    experienceLevel: "Mid",
    location: "New York",
    type: "Full-time",
    description: "Build user-facing web applications.",
    responsibilities: ["Develop new features", "Maintain codebase"],
    expiryDate: "2024-12-15", // Add expiry date
    postedDaysAgo: 3, // Add posted days ago
  },
  {
    id: "2",
    title: "Backend Developer",
    company: "Soft Inc.",
    experience: "3+ years",
    experienceLevel: "Senior",
    location: "San Francisco",
    type: "Contract",
    description: "Work on server-side logic and APIs.",
    responsibilities: ["Design database schemas", "Optimize backend"],
    expiryDate: "2024-12-20",
    postedDaysAgo: 5,
  },
];
