import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomInput from '../Inputs/CustomInput';
import { useTheme } from '@mui/material/styles';
import { useMutation } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { emailForm } from '../../api/services'; // Importing emailForm from services
import { dpr_brchure } from '../../assets';

interface ModalFormProjectProps {
  open: boolean;
  handleClose: () => void;
  projectImage: string; // Image URL passed as prop
  projectId: string; // Project ID
  projectName: string; // Project Name
}

const ModalFormProject: React.FC<ModalFormProjectProps> = ({
  open,
  handleClose,
  projectImage,
  projectId,
  projectName,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValid =
      formValues.name.trim() !== '' &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email) &&
      /^[0-9]{10}$/.test(formValues.mobile) &&
      formValues.message.trim() !== '';
    setIsFormValid(isValid);
  }, [formValues]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'mobile') {
      if (/^\d*$/.test(value) && value.length <= 10) {
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const { mutate, isLoading } = useMutation(emailForm, {
    onSuccess: () => {
      toast.success('Brochure request sent successfully!');
      downloadBrochure();
      handleClose();
      resetForm();
    },
    onError: () => {
      toast.error('Failed to send the brochure request.');
    },
  });

  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = dpr_brchure;
    link.download = `${projectName}_Brochure.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const resetForm = () => {
    setFormValues({ name: '', email: '', mobile: '', message: '' });
    setIsFormValid(false);
  };

  const handleSubmit = () => {
    if (!isFormValid) {
      toast.error('Please fill out all fields correctly.');
      return;
    }

    mutate({
      name: formValues.name,
      email: formValues.email,
      phoneNumber: formValues.mobile,
      message: formValues.message,
      type: 'project',
      projectName: projectName,
    });
  };

  return (
    <>
      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
            width: '100%',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            color: '#333',
            borderRadius: '8px',
            padding: 3,
            maxWidth: '900px', // Increased max-width for better view on large screens
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: '16px',
            top: '16px',
          }}
        >
          <IconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid container spacing={3}> {/* Added spacing between the grid items */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: '100%',
                height: { xs: 280, md: 400 },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                overflow: 'hidden',
                boxShadow: 1,
              }}
            >
              <img
                src={`${process.env.NEXT_PUBLIC_STORAGE_DN_URL || 'https://dprstorage.b-cdn.net'}${projectImage}`}
                alt="Project"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="body2"
              sx={{
                marginBottom: theme.spacing(1.5),
                fontWeight: 600,
                textAlign: 'left',
              }}
            >
              {/* add some content here so that this modal will be better and great okay so now give me broo */}
              Fill the form below to download the brochure
            </Typography>

            <DialogContent dividers sx={{ padding: theme.spacing(2) }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomInput
                    name="name"
                    placeholder="Your Name"
                    value={formValues.name}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    name="email"
                    type="email"
                    placeholder="Your Email"
                    value={formValues.email}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    name="mobile"
                    type="tel"
                    placeholder="Your Mobile Number"
                    value={formValues.mobile}
                    onChange={handleChange}
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    name="message"
                    placeholder="Your Message"
                    value={formValues.message}
                    onChange={handleChange}
                    fullWidth
                    required
                    multiline
                    rows={4}
                    inputProps={{
                      maxLength: 500,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions sx={{ padding: theme.spacing(1) }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
                sx={{ 
                  marginRight: 'auto',
                  padding: '5px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '40px',
                 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={!isFormValid || isLoading}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px 16px',
                  height: '40px',
                  backgroundColor: isFormValid && !isLoading ? '#30779d' : '#30779d80',
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} sx={{ color: '#fff' }} />
                ) : (
                  'Submit & Download'
                )}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ModalFormProject;
