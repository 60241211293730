import React from 'react';
import { Grid, Box, Container, Typography } from '@mui/material';
import { mission, vision, mission1 } from '../../assets';

const Vision: React.FC = () => {
  return (
    <Box sx={{ background: 'white', padding: { xs: '10px 0', md: '20px 0' } }}>
      <Container>
        <Box sx={{ width: '100%', padding: { xs: '10px 0', md: '0px 0' } }}>
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2 }}>
            
            {/* Mission Section */}
            <Grid 
              item 
              xs={12} 
              md={7} 
              sx={{ 
                textAlign: { xs: 'center', md: 'start' }, 
                display: 'flex', 
                justifyContent: 'center', 
                flexDirection: 'column' 
              }}
            >
              <Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    textAlign: { xs: 'center', md: 'start' }, 
                    fontWeight: '600', 
                    mb: 2,
                    fontSize: { xs: '0.875rem', md: '1.2rem' } 
                  }}
                >
                  Our Mission
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                paragraph 
                sx={{ 
                  textAlign: 'justify', 
                  fontSize: { xs: '0.875rem', md: '1rem' } 
                }}
              >
                We provide forward-thinking real estate solutions that build trust, create enduring partnerships, and ensure excellence in every project. Our approach guarantees efficiency and lasting impact for every client, empowering them to achieve their goals with confidence and clarity.
              </Typography>
              <Typography 
                variant="caption" 
                paragraph 
                sx={{ 
                  textAlign: 'justify', 
                  fontSize: { xs: '0.875rem', md: '1rem' } 
                }}
              >
                By leveraging cutting-edge technologies and deep industry insights, we aim to deliver innovative, customer-centric solutions that stand the test of time.
              </Typography>
            </Grid>
            
            {/* Mission Image */}
            <Grid item xs={12} md={5}>
              <Box sx={{ height: '100%', width: '100%' }}>
                <img 
                  src={mission} 
                  alt="Our Mission"  
                  style={{ 
                    borderRadius: '20px 0 20px 20px', 
                    clipPath: 'polygon(0 0, 90% 0, 100% 10%, 100% 100%, 0 100%)', 
                    height: 'auto', 
                    width: '100%', 
                    maxHeight: '330px' 
                  }}
                />
              </Box>
            </Grid>

            {/* Vision Section */}
            <Grid item xs={12} md={5}>
              <Box sx={{ height: '100%', width: '100%' }}>
                <img 
                  src={vision} 
                  alt="Our Vision"  
                  style={{ 
                    borderRadius: '20px 0 20px 20px', 
                    clipPath: 'polygon(0 0, 90% 0, 100% 10%, 100% 100%, 0 100%)', 
                    height: 'auto', 
                    width: '100%', 
                    maxHeight: '330px' 
                  }}
                />
              </Box>
            </Grid>
            <Grid 
              item 
              xs={12} 
              md={7} 
              sx={{ 
                textAlign: { xs: 'center', md: 'start' }, 
                display: 'flex', 
                justifyContent: 'center', 
                flexDirection: 'column' 
              }}
            >
              <Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    textAlign: { xs: 'center', md: 'start' }, 
                    fontWeight: '600', 
                    mb: 2,
                    fontSize: { xs: '0.875rem', md: '1.2rem' } 
                  }}
                >
                  Our Vision
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                paragraph 
                sx={{ 
                  textAlign: 'justify', 
                  fontSize: { xs: '0.875rem', md: '1rem' } 
                }}
              >
                We aspire to lead the real estate industry by transforming urban spaces, fostering sustainable, connected communities where every investment drives prosperity and growth. Our vision is to shape thriving environments that harmonize modern living with environmental responsibility.
              </Typography>
              <Typography 
                variant="caption" 
                paragraph 
                sx={{ 
                  textAlign: 'justify', 
                  fontSize: { xs: '0.875rem', md: '1rem' } 
                }}
              >
                By setting new benchmarks in quality and innovation, we aim to redefine urban landscapes, creating spaces that inspire and uplift communities for generations to come.
              </Typography>
            </Grid>

            {/* Values Section */}
            <Grid 
              item 
              xs={12} 
              md={7} 
              sx={{ 
                textAlign: { xs: 'center', md: 'start' }, 
                display: 'flex', 
                justifyContent: 'center', 
                flexDirection: 'column' 
              }}
            >
              <Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    textAlign: { xs: 'center', md: 'start' }, 
                    fontWeight: '600', 
                    mb: 2,
                    fontSize: { xs: '0.875rem', md: '1.2rem' } 
                  }}
                >
                  Our Values
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                paragraph 
                sx={{ 
                  textAlign: 'justify', 
                  fontSize: { xs: '0.875rem', md: '1rem' } 
                }}
              >
                We uphold integrity, excellence, and innovation, fostering trust in every endeavor. Our core values drive us to prioritize sustainability, ensuring that our projects leave a positive, lasting legacy for future generations.
              </Typography>
              <Typography 
                variant="caption" 
                paragraph 
                sx={{ 
                  textAlign: 'justify', 
                  fontSize: { xs: '0.875rem', md: '1rem' } 
                }}
              >
                With a strong commitment to ethical practices and continuous improvement, we aim to create communities that support a fulfilling and connected lifestyle.
              </Typography>
            </Grid>
            
            {/* Values Image */}
            <Grid item xs={12} md={5}>
              <Box sx={{ height: '100%', width: '100%' }}>
                <img 
                  src={mission1} 
                  alt="Our Values"  
                  style={{ 
                    borderRadius: '20px 0 20px 20px', 
                    clipPath: 'polygon(0 0, 90% 0, 100% 10%, 100% 100%, 0 100%)', 
                    height: 'auto', 
                    width: '100%', 
                    maxHeight: '350px' 
                  }}
                />
              </Box>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Vision;
