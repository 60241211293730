import React from 'react';
import { Box, Typography, Grid, Card, Button, Container } from '@mui/material';
import { ServiceImages } from '../../assets';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

const slugify = (title: string): string => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '') // Remove non-alphanumeric characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .trim();
};

const totaldata = [
  {
    id: 1,
    title: "Custom Designed Sales Mechanisms",
    description: "Establishing an Efficient Tailormade Sales Mechanism to Suit each Project based on the Product & Location",
    logo: ServiceImages.service1,
  },
  {
    id: 2,
    title: "Brand Consulting & Enhanced Customer Experience",
    description: "Boosting confidence in customers and teams with friendly policies and incentives for loyalty, performance, and exceptional customer experience through HR",
    logo: ServiceImages.service2,
  },
  {
    id: 3,
    title: "Tech Tools & Automation Systems Implementation",
    description: "Identify & Match the Project requirement with the Best suited HRM & CRM Tools for Reporting & Tracking & to ensure enforceability across the organization",
    logo: ServiceImages.service3,
  },
  {
    id: 4,
    title: "Vendor Management Services",
    description: "Engage vendors in various spheres in the organization wrt Sales, Marketing & Business Development",
    logo: ServiceImages.service4,
  },
  {
    id: 5,
    title: "Networking & Visibility Enhancement",
    description: "Align Channel Partner network to the organization to increase word of mouth marketing/ branding & Sales Mobilisation",
    logo: ServiceImages.service5,
  },
  {
    id: 6,
    title: "Tracking & Reporting Systems",
    description: "Drive the teams & track workforce performance weekly/monthly to make sure the set processes are being followed in order to achieve the desired output",
    logo: ServiceImages.service6,
  },
  {
    id: 7,
    title: "Marketing Campaigns & Strategies",
    description: "To prepare a Marketing Calendar & Run regular campaigns both ATL & BTL activities",
    logo: ServiceImages.service7,
  },
  {
    id: 8,
    title: "Strategic Alliances & Associations",
    description: "Creating strategic alliances & establishing tie-ups with the organization with Bankers, Investors, Marketers, Vendors & Associations that help in quick turn around",
    logo: ServiceImages.service8,
  },
  {
    id: 9,
    title: "Management Consulting Services",
    description: "Optimizing practices for organizational growth, refining functions, and implementing end-to-end improvements for operational excellence across the organization",
    logo: ServiceImages.service9,
  },
];

const Services = () => {
  const navigate = useNavigate();

  const handleNavigation = (id: number, title: string) => {
    const slug = slugify(title); // Convert title to a slug
    navigate(`/ourservices/${id}/${slug}`); // Navigate using ID and slug
  };

  return (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Container>
        <Typography
          variant="h4"
          sx={{
            mb: 2,
            color: '#333',
            fontSize: { xs: '1.5rem', md: '2rem' },
            mt: 6,
            fontWeight: '500',
          }}
        >
          Our Services
        </Typography>
        <Typography variant="body2" mt={2} mb={2} sx={{ textAlign: 'justify' }}>
          We specialize in crafting tailored sales strategies for real estate agencies in Hyderabad, aligning with each project's unique product and location needs. Our approach enhances customer experience through expert brand consulting, customer-friendly policies, and loyalty incentives. By leveraging advanced HRM and CRM tools, we ensure efficient reporting, tracking, and accountability. We actively manage vendors and drive sales, marketing, and business development through targeted ATL and BTL campaigns. Strategic partnerships with bankers, investors, and marketers fuel growth, while our management consulting services optimize operations, ensuring long-term success.        </Typography>
        <Grid container spacing={2}>
          {totaldata.map((data, index) => (
            <Grid item xs={12} sm={5} md={3} key={index}>
              <Card
                onClick={() => handleNavigation(data.id, data.title)}
                sx={{
                  m: 2,
                  textAlign: 'left',
                  bgcolor: '#E6F0F3',
                  padding: { xs: 2, md: 4 },
                  borderRadius: 3,
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  height: { xs: 'auto', md: '400px' },
                  '&:hover': {
                    bgcolor: '#30779d',
                    '& .MuiTypography-root': { color: 'white' },
                  },
                }}
              >
                <Box
                  component="img"
                  src={data.logo}
                  alt={`${data.title} logo`}
                  sx={{ width: { xs: '45px', md: '55px' }, }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: '600',
                    color: 'black',
                  }}
                >
                  {data.title}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: { xs: '10px', md: '12px' },
                    color: 'black',
                  }}
                >
                  {data.description}
                </Typography>
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    alignSelf: 'flex-start', 
                    bgcolor: '#30779d',
                    height: '35px',
                    fontSize: '12px',
                    width: '140px',
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#205973',
                    },
                  }}
                  onClick={() => handleNavigation(data.id, data.title)} // Pass both ID and title
                >
                  Read More
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;
