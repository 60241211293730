import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, List, ListItem } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { servicebannerone } from '../../../assets';

const tabsData = [
  {
    id: 1,
    bannerImage: servicebannerone, 
    title: "Custom Designed Sales Mechanisms",
    description: "Selling a property is more than just putting it on the market—it’s about understanding its value, showcasing its strengths, and connecting with the right buyers. At Deccan Progressive Realty, we specialize in creating Custom Designed Sales Mechanisms to help you sell your property faster, smarter, and at the best possible value. \n \n With our headquarters in Hyderabad, we bring deep insights into the local real estate market, combining our expertise with innovative strategies to deliver exceptional results. Whether you’re selling a residential property, commercial space, or land, we design tailored solutions that align with your goals and ensure a seamless sales experience.  ",
    sections: [
      {
        sectionTitle: "How Our Custom Designed Sales Mechanisms Work",
        subsections: [
          {
            title: "Property Evaluation: Understanding Your Asset",
            details: [
              "Identifying key selling points such as location, design, amenities, and potential.",
              "Assessing the property’s current market value through comparative analysis.",
              "Highlighting any improvements or staging opportunities to enhance its appeal."
            ]
          },
          {
            title: "Strategic Sales Planning: Crafting Your Roadmap  ",
            details: [
              "Target Audience Identification:** Understanding who your ideal buyers are and tailoring messaging to appeal to them.",
              "Marketing Plan Creation: Leveraging both online and offline channels to maximize visibility, including social media, real estate platforms, email campaigns, and local outreach. ",
              "Pricing Strategy: Setting a competitive yet profitable price based on market data, trends, and your expectations."
            ]
          },
          {
            title: "Seamless Execution: Bringing the Plan to Life",
            details: [
              "Professional Marketing: Using high-quality photography, videos, and virtual tours to showcase your property.",
              "Widespread Advertising: Listing your property on top real estate platforms, social media channels, and leveraging our network for maximum exposure.",
              "Buyer Engagement: Conducting open houses, private viewings, and virtual showings to attract serious buyers.",
              "Negotiation Support: Our experienced team handles negotiations with potential buyers, ensuring you get the best deal while protecting your interests.",
              "Closing Assistance: From documentation to legal formalities, we guide you through the final steps for a smooth transaction."
            ]
          },
          {
            title: "Our Tools and Techniques",
            details: [
              "Market Research Tools: Advanced analytics to understand trends and set the right price.",
              "Digital Marketing Expertise: Using SEO, targeted ads, and social media campaigns to amplify your property’s reach. ",
              "Home Staging Recommendations: Helping you create an inviting atmosphere that appeals to potential buyers.  ",
              "Professional Photography and Videography: Capturing your property in its best light to make a lasting impression.  ",
            ]
          },
          {
            title: "Our Proven Track Record",
            details: [
              "Faster Sales Timelines: Properties sold within 30% less time than the market average.  ",
              "Increased ROI: Clients consistently achieve higher-than-expected sale prices due to our strategic pricing and marketing.  ",
              "Satisfied Clients: A growing list of happy property owners who recommend us for our professionalism, dedication, and results. ",
            ]
          },
          {
            title: "What Makes Hyderabad’s Market Unique?  ",
            details: [
              "A booming IT and tech industry attracting professionals and investors. ",
              "Affordable yet appreciating property rates compared to other metropolitan cities. ",
              "Increasing demand for premium properties in key areas like Jubilee Hills, Gachibowli, and Madhapur.  ",
              "A mix of traditional and modern architectural appeal that caters to diverse buyer preferences."
            ]
          },
          {
            title : "Client-Centric Approach",
            details: [
              "Regular Updates: We keep you informed at every stage of the sales process.  ",
              "Clear Communication: No hidden costs or surprises—just honest advice and straightforward processes.",
              "After-Sales Support: Need assistance after the sale? We’re here to help with property handovers and beyond.  ",
            ]

          }
        ]
      },
      {
        sectionTitle: "Why Choose Deccan Progressive Realty for Your Sales Needs?",
        subsections: [
          "Tailored Solutions: We don’t believe in one-size-fits-all strategies. Every property has its unique features, and we design sales mechanisms that highlight these attributes to attract the right buyers",
          "Local Expertise: With years of experience in Hyderabad’s dynamic real estate market, we understand the trends, buyer behavior, and pricing strategies needed to stand out.",
          "Faster Sales: Using smart marketing techniques, strategic outreach, and a vast network, we ensure your property reaches the right audience, reducing the time it spends on the market. ",
          "Maximum Value: Our data-driven approach to pricing ensures your property is positioned competitively to get the best returns while avoiding underpricing or overpricing.",
          "Comprehensive Support: From property evaluation to deal closure, we provide end-to-end support, handling everything so you can focus on what matters most to you."
        ]
      }
    ]
  },
  {
    id: 2,
    bannerImage: servicebannerone, 
    title: "Brand Consulting & Enhanced Customer Experience",
    description: "In a competitive real estate market like Hyderabad, standing out requires more than just exceptional projects—it demands a strong, resonant brand and unparalleled customer experiences. At Deccan Progressive Realty, we specialize in Brand Consulting and Enhanced Customer Experience, helping real estate developers and property businesses build memorable brands and foster trust with their audiences.  \n \n Whether you’re launching a new project, rebranding, or looking to elevate customer satisfaction, our tailored services deliver results that drive long-term success. ",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          },
          {
            title: "Brand Consulting & Customer Experience Enhancement",
            details: [
              "Brand Identity Development: Crafting a unique brand story, logo, and visual elements that resonate with your target audience.",
              "Customer Journey Mapping: Understanding the buyer’s path from discovery to purchase and beyond, to enhance every touchpoint.",
              "Loyalty Programs & Incentives: Creating rewards and benefits that keep customers engaged and coming back for more.",
              "Customer Feedback & Surveys: Collecting insights to improve your services, products, and overall customer experience."
            ]
          },
          {
            title: "Digital Transformation & Technology Integration",
            details: [
              "Digital Marketing Strategies: Leveraging online channels to reach a wider audience and drive engagement.",
              "CRM & Automation Tools: Implementing systems that streamline customer interactions, lead management, and sales processes.",
              "Data Analytics & Reporting: Using data to track performance, identify trends, and make informed decisions for your brand.",
              "Customer Service Training: Equipping your team with the skills and knowledge to deliver exceptional service at every touchpoint."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Brand Consulting Matters in Real Estate",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity.",
          "Brand Consulting & Customer Experience Enhancement",
        ]
      }
    ]
  },
  {
    id: 3,
    bannerImage: servicebannerone, 
    title: "Tech Tools & Automation Systems Implementation",
    description: "At Deccan Progressive Realty, we believe in creating innovative and tailored strategies to maximize sales for your real estate projects. Our custom-designed sales mechanisms are crafted to meet the unique needs of each client, ensuring that every property stands out in the competitive market.",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          },
          {
            title: "Tech Tools & Automation Systems Implementation",
            details: [
              "CRM & Automation Tools: Implementing systems that streamline customer interactions, lead management, and sales processes.",
              "Data Analytics & Reporting: Using data to track performance, identify trends, and make informed decisions for your brand.",
              "Customer Service Training: Equipping your team with the skills and knowledge to deliver exceptional service at every touchpoint."
            ]
          },
          {
            title: "Why Choose Us?",
            details: [
              "Expertise in designing sales strategies for both residential and commercial properties.",
              "Proven track record of success in boosting sales and enhancing client satisfaction.",
              "Commitment to delivering solutions that align with your business goals and brand identity."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity."
        ]
      }
    ]
  },
  {
    id: 4,
    bannerImage: servicebannerone, 
    title: "Vendor Management Services",
    description: "At Deccan Progressive Realty, we believe in creating innovative and tailored strategies to maximize sales for your real estate projects. Our custom-designed sales mechanisms are crafted to meet the unique needs of each client, ensuring that every property stands out in the competitive market.",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity."
        ]
      }
    ]
  },
  {
    id: 5,
    bannerImage: servicebannerone, 
    title: "Networking & Visibility Enhancement",
    description: "At Deccan Progressive Realty, we believe in creating innovative and tailored strategies to maximize sales for your real estate projects. Our custom-designed sales mechanisms are crafted to meet the unique needs of each client, ensuring that every property stands out in the competitive market.",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity."
        ]
      }
    ]
  },
  {
    id: 6,
    bannerImage: servicebannerone, 
    title: "Tracking & Reporting Systems",
    description: "At Deccan Progressive Realty, we believe in creating innovative and tailored strategies to maximize sales for your real estate projects. Our custom-designed sales mechanisms are crafted to meet the unique needs of each client, ensuring that every property stands out in the competitive market.",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity."
        ]
      }
    ]
  },
  {
    id: 7,
    bannerImage: servicebannerone, 
    title: "Marketing Campaigns & Strategies",
    description: "At Deccan Progressive Realty, we believe in creating innovative and tailored strategies to maximize sales for your real estate projects. Our custom-designed sales mechanisms are crafted to meet the unique needs of each client, ensuring that every property stands out in the competitive market.",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity."
        ]
      }
    ]
  },
  {
    id: 8,
    bannerImage: servicebannerone, 
    title: "Strategic Alliances & Associations",
    description: "At Deccan Progressive Realty, we believe in creating innovative and tailored strategies to maximize sales for your real estate projects. Our custom-designed sales mechanisms are crafted to meet the unique needs of each client, ensuring that every property stands out in the competitive market.",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity."
        ]
      }
    ]
  },
  {
    id: 9,
    bannerImage: servicebannerone, 
    title: "Management Consulting Services",
    description: "At Deccan Progressive Realty, we believe in creating innovative and tailored strategies to maximize sales for your real estate projects. Our custom-designed sales mechanisms are crafted to meet the unique needs of each client, ensuring that every property stands out in the competitive market.",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity."
        ]
      }
    ]
  },

];

const SingleService = () => {
  const { id } = useParams();  
  const service = tabsData.find(tab => tab.id.toString() === id);  

  if (!service) {
    return <Typography variant="h5">Service not found</Typography>;
  }

  return (
    <div>
      <Box sx={{ mb: 4 }}>
        {/* Tab Banner Image */}
        <Box
          component="img"
          src={service.bannerImage}
          alt={service.title}
          sx={{
            width: '100%',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
            },
            marginBottom: '1rem',
          }}
        />
        <Typography variant="h5" color="primary" sx={{ mb: 2, fontWeight: 600,textAlign: 'left' }}>
          {service.title}
        </Typography>

        {/* Tab Description */}
        <Typography variant="body2" sx={{ mb: 3, textAlign: 'justify' }}>
          {service.description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))} 
        </Typography>

        {/* Tab Sections */}
        {service.sections.map((section, sectionIndex) => (
          <Box key={sectionIndex} sx={{ mb: 4 }}>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 600, textAlign: 'left' }}>
              {section.sectionTitle}
            </Typography>

            {/* List of Subsections */}
            {section.subsections.map((subsection, subIndex) => (
              <Box key={subIndex} sx={{ mb: 2 }}>
                {/* Check if subsection has details */}
                {typeof subsection === 'object' && 'details' in subsection ? (
                  <>
                    <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 ,textAlign:'left',mt:2}}>
                      {subsection.title}
                    </Typography>
                    <List>
                      {subsection.details.map((detail, detailIndex) => (
                        <ListItem key={detailIndex} sx={{ display: 'flex', alignItems: 'flex-start', gap: '5px', padding: 0 }}>
                          <FiberManualRecordIcon sx={{ fontSize: '10px', color: '#30779d', marginTop: '4px' }} />
                          <Typography variant="body2">{detail}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : (
                  <List>
                  <ListItem sx={{ display: 'flex', alignItems: 'flex-start', gap: '5px', padding: 0 }}>
                    <FiberManualRecordIcon sx={{ fontSize: '10px', color: '#30779d', marginTop: '4px' }} />
                    <Typography variant="body2">{subsection}</Typography>
                  </ListItem>
                </List>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default SingleService;
