import React, { useState } from 'react';
import { Box, Button, Typography, Checkbox, FormControlLabel, LinearProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CustomInput from '../../../../../components/Inputs/CustomInput'; // Assuming CustomInput is defined correctly
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AddLead } from '../../../../../api/services'; // Assuming this API service exists
import { Project } from '../../../ProjectInterface';



interface ScheduleFormProps{
  projectData:Project;
}
const ScheduleForm:  React.FC<ScheduleFormProps> = ({ projectData }) => {
  // Form state management
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    message: '',
    iAgree: false,
    homeLoan: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const mutation = useMutation(AddLead, {
    onSuccess: () => {
      toast.success('Successfully submitted!');
    },
    onError: (error: any) => {
      toast.error(`${error.response?.data?.message || 'Submission failed'}`);
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!formData.iAgree) {
      toast.error('Please agree to the terms and conditions.');
      return;
    }
    const payload = { ...formData, type: 'project' ,projectName:projectData.name};
    mutation.mutate(payload);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        width: '100%',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
      }}
    >
      {/* Title */}
      <Typography variant="body1" component="h1" gutterBottom sx={{ fontWeight: 600, mb: '0px' }}>
        Schedule a tour
      </Typography>

      {/* Subtext */}
      <Typography variant="body2" sx={{ marginBottom: '20px', fontSize: { xs: '14px', md: '16px' }, textAlign: 'center' , mb: '15px'}}>
        Choose your preferred day
      </Typography>

      {/* Form */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
        }}
      >
        {/* Name Field */}
        <CustomInput
          id="name"
          name="name"
          placeholder="Name"
          fullWidth
          required
          value={formData.name}
          onChange={handleInputChange}
        />

        {/* phoneNumber Field */}
        <CustomInput
          id="phoneNumber"
          name="phoneNumber"
          placeholder="phoneNumber"
          fullWidth
          required
          value={formData.phoneNumber}
          onChange={handleInputChange}
        />

        {/* Email Field */}
        <CustomInput
          id="email"
          name="email"
          placeholder="Email"
          type="email"
          fullWidth
          required
          value={formData.email}
          onChange={handleInputChange}
        />

        {/* Message Field */}
        <CustomInput
          id="message"
          name="message"
          placeholder="Enter Your Message"
          fullWidth
          multiline
          rows={3}
          value={formData.message}
          onChange={handleInputChange}
        />

        {/* Checkbox for Terms */}
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.iAgree}
              onChange={handleCheckboxChange}
              name="iAgree"
              color="primary"
            />
          }
          label={
            <Typography variant="caption" style={{ fontSize: '11px' }}>
              I agree to be contacted by Housing and agents via WhatsApp, SMS, phoneNumber, email, etc.
            </Typography>
          }
        />

        {/* Checkbox for Home Loan */}
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.homeLoan}
              onChange={handleCheckboxChange}
              name="homeLoan"
              color="primary"
            />
          }
          label={
            <Typography variant="caption" style={{ fontSize: '12px' }}>
              I am interested in Home Loans
            </Typography>
          }
        />

        {/* Submit Button */}
        <Button
          variant="contained"
          color="error"
          type="submit"
          fullWidth
          endIcon={<SendIcon />}
          sx={{ 
            padding: '10px 0', 
            fontWeight: '600',
            fontSize: '14px',
            textTransform: 'capitalize',
            }}
          disabled={mutation.isLoading}
        >
          {mutation.isLoading ? <LinearProgress sx={{ height: '2px' }} /> : 'Submit a Tour Request'}
        </Button>

        {/* Status Messages */}
        {mutation.isError && (
          <Typography color="error" variant="body2">
            {mutation.error instanceof Error ? mutation.error.message : 'Submission failed. Please try again.'}
          </Typography>
        )}
        {mutation.isSuccess && (
          <Typography color="primary" variant="body2">
            Form submitted successfully!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ScheduleForm;
