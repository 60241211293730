import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { gsap } from 'gsap'; // Import GSAP
import {
  gallery1, gallery2, gallery3, gallery4, gallery5, gallery6,
  gallery7, gallery8, gallery9, gallery10, gallery11, gallery12,
  gallery13, gallery14, gallery18, NewGallery,
} from '../../assets';
import { Box, Breadcrumbs, Button, Container, Grid, MenuItem, Select, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}

const itemData = [
  { img: NewGallery.img8, rows: 2, cols: 1 },
  { img: gallery2 },
  { img: gallery3 },
  { img: NewGallery.img3, rows: 2, cols: 1 },
  { img: gallery18, cols: 2 },
  { img: gallery6, rows: 2, cols: 1 },
  { img: gallery7 },
  { img: NewGallery.img4 },
  { img: gallery9, rows: 2, cols: 1 },
  { img: gallery1, cols: 2 },
  { img: NewGallery.img5, rows: 2, cols: 1 },
  { img: gallery12 },
  { img: NewGallery.img2 },
  { img: gallery14, rows: 2, cols: 1 },
  { img: gallery4, cols: 2 },
  { img: NewGallery.img1, rows: 2, cols: 1 },
  { img:  gallery13},
  { img: gallery5 },
  { img: NewGallery.img7, rows: 2, cols: 1 },
  { img: gallery11, cols: 2 },
  { img: NewGallery.img6, rows: 2, cols: 1 },
  { img:  gallery8},
  { img: gallery10 },
];

const LayOut: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const imageRef = React.useRef<HTMLImageElement | null>(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(25); // Default to 10 items per page

  const handleImageClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const animateImage = (direction: string) => {
    if (!imageRef.current) return;

    const tl = gsap.timeline();
    const distance = direction === 'next' ? 600 : -600;

    // Animation sequence
    tl.to(imageRef.current, {
      x: distance,
      opacity: 0,
      duration: 0.5,
      ease: 'power2.out',
    })
      .set(imageRef.current, { x: -distance })
      .to(imageRef.current, {
        x: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'power2.in',
      });
  };

  const handleNext = () => {
    animateImage('next');
    setSelectedIndex((prevIndex) => (prevIndex + 1) % itemData.length);
  };

  const handlePrev = () => {
    animateImage('prev');
    setSelectedIndex((prevIndex) => (prevIndex - 1 + itemData.length) % itemData.length);
  };

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeItemsPerPage = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1); 
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedItems = itemData.slice(startIndex, startIndex + itemsPerPage);

  // Calculate columns per page based on the itemsPerPage value
  const columns = Math.min(itemsPerPage, 4); // You can adjust the maximum columns per row if necessary

  return (

    <Container>
      <Box sx={{ my: 2 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ textDecoration: 'none' }} color="inherit" to="/home">
        <Typography variant="body2" gutterBottom>
          Home
        </Typography>
        </Link>
        <Link style={{ textDecoration: 'none' }} color="inherit" to="/gallery">
        <Typography variant="body2" gutterBottom>
          Gallery
        </Typography>
        </Link>
      </Breadcrumbs>
    </Box>
      {/*  */}
      
      <Typography variant='h5' color='primary' sx={{fontWeight:600,mt:4,textAlign:'left'}}>
        Our Gallery
      </Typography>

      
      <ImageList
        sx={{
          width: '100%',
          height: 'auto',
          gap: 8,
        }}
        variant="quilted"
        cols={columns}
        rowHeight={200}
      >
        {selectedItems.map((item, index) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
            sx={{
              height: 200,
              width: '100%',
              overflow: 'hidden',
            }}
            onClick={() => handleImageClick(startIndex + index)} // Use global index
          >
            <img
              {...srcset(item.img, 200, item.rows, item.cols)}
              loading="lazy"
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      {/* Rows per page selection */}
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <Typography component="span">Rows per Images:</Typography>
        <Select
          value={itemsPerPage}
          onChange={(e) => handleChangeItemsPerPage(Number(e.target.value))}
          variant="outlined"
          sx={{
            width: 'auto',
            height: '25px',
            background: '#FFFFFF',
            borderRadius: '5px',
            textAlign: 'center',
            lineHeight: '20px',
            marginLeft: '10px',
            overflow: 'hidden',
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </Grid>

      {/* Modal for image view */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            position: 'relative',
            width: '100%',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 30,
              right: 55,
              color: 'white',
              zIndex: 1,
            }}
          >
            <CloseIcon style={{ fontSize: '40px' }} />
          </IconButton>

          <img
            ref={imageRef}
            src={itemData[selectedIndex].img}
            alt="Full view"
            style={{
              width: '600px',
              height: 'auto',
              objectFit: 'contain',
            }}
          />

          <Box
            style={{
              marginTop: '10px',
              fontSize: '16px',
              color: '#fff',
            }}
          >
            {selectedIndex + 1} / {itemData.length}
          </Box>

          <Button
            onClick={handlePrev}
            style={{
              position: 'absolute',
              top: '50%',
              left: '15%',
              transform: 'translateY(-50%)',
              background: 'none',
              color: 'white',
              border: 'none',
              padding: '5px',
              cursor: 'pointer',
              fontSize: '50px',
            }}
          >
            &lt;
          </Button>

          <Button
            onClick={handleNext}
            style={{
              position: 'absolute',
              top: '50%',
              right: '15%',
              transform: 'translateY(-50%)',
              background: 'none',
              color: 'white',
              border: 'none',
              padding: '5px',
              cursor: 'pointer',
              fontSize: '50px',
            }}
          >
            &gt;
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default LayOut;
