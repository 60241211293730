import React from 'react';
import { TextField, DialogContent, DialogActions, DialogTitle, IconButton, Button, Typography, FormControlLabel, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomInput from '../Inputs/CustomInput';
import { useMutation } from 'react-query';
import { AddLead } from '../../api/services';
import { toast } from 'react-toastify';

interface HomeSeekarFormProps {
  formData: {
    name: string;
    phoneNumber: string;
    email: string;
    message?: string;
    iAgree: boolean;
    homeLoan: boolean;
  };
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitForm: () => void;
  handleCloseForm: () => void;
}

const HomeSeekarForm: React.FC<HomeSeekarFormProps> = ({
  formData,
  handleFormChange,
  handleSubmitForm,
  handleCloseForm,
}) => {
  const mutation = useMutation(AddLead, {
    onSuccess: () => {
      toast.success('Successfully submitted!');
    },
    onError: (error: any) => {
      toast.error(`${error.response.data.message}`);
    },
  });

  const submitForm = () => {
    const temp = { ...formData, type: 'contact' };
    mutation.mutate(temp);
  };

  return (
    <>
      <DialogTitle sx={{
        height:'15px'
      }}>
        
        <Typography variant='body2' color='primary' >

          If You Are a Home Seeker
          </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseForm}
          sx={{
            position: 'absolute',
            right: 2,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ 
        maxHeight: 'calc(100vh - 150px)',
         overflowY: 'auto',
         boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.2)', // Full-sided inset shadow
         padding: '16px', // Padding inside the content
         borderRadius: '8px', // Optional: For rounded edges
         backgroundColor: '#fff',
         }}>
        <Typography variant="caption" paragraph sx={{ textAlign: 'justify' }} mt={1}>
          Your search for the perfect home ends here at DPR. With curated property options tailored to your needs, we simplify the journey from exploration to ownership. Whether it’s location, budget, or lifestyle, our platform ensures you find a home that fits your vision. Let us guide you to a decision you’ll cherish for years to come.
        </Typography>
        <CustomInput
          name="name"
          placeholder="Your Name"
          fullWidth
          value={formData.name}
          onChange={handleFormChange}
          required
          sx={{ mb: 2 }}
        />
        <CustomInput
          name="phoneNumber"
          placeholder="Mobile Number"
          fullWidth
          value={formData.phoneNumber}
          onChange={handleFormChange}
          required
          sx={{ mb: 2 }}
        />
        <CustomInput
          name="email"
          placeholder="Your Email"
          fullWidth
          value={formData.email}
          onChange={handleFormChange}
          required
          sx={{ mb: 2 }}
        />
        <CustomInput
          name="message"
          placeholder="Message"
          fullWidth
          value={formData.message}
          onChange={handleFormChange}
          required
          multiline
          rows={4}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formData.iAgree}
              onChange={handleFormChange}
              name="iAgree"
              color="primary"
            />
          }
          label={
            <Typography variant="body2" style={{ fontSize: '14px' }}>
              I agree to be contacted by Housing and agents via WhatsApp, SMS, phone, email etc
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.homeLoan}
              onChange={handleFormChange}
              name="homeLoan"
              color="primary"
            />
          }
          label={
            <Typography variant="body2" style={{ fontSize: '14px' }}>
              I am interested in Home Loans
            </Typography>
          }
        />

        {mutation.isLoading && <Typography>Submitting...</Typography>}
        {mutation.isError && <Typography color="error">Submission failed. Please try again.</Typography>}
        {mutation.isSuccess && <Typography color="primary">Form submitted successfully!</Typography>}
      </DialogContent>

      <DialogActions
        sx={{
          position: 'sticky',  // Fixes the DialogActions at the bottom
          bottom: 0,
          left: 0,
          right: 0,
          padding: '10px',
          backgroundColor: 'white',
          justifyContent: 'space-between',
          boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',  // Add box shadow here
        }}
      >
        <Button onClick={handleCloseForm} sx={{
          height:'30px',
          fontSize:'14px'
          }}>
          Cancel
        </Button>
        <Button
          sx={{
            height:'30px',
            fontSize:'14px'
            }}
          onClick={submitForm} 
          color="primary" 
          disabled={mutation.isLoading}
         >
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export default HomeSeekarForm;
