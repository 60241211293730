import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { Card, CardActionArea, CardMedia, CardContent } from '@mui/material';
import map from '../../assets/images/map.png'; 
import communication from '../../assets/images/communication.png'; 
import phone from '../../assets/images/phone.png'; 


const Location:React.FC = () => {
  return (

    <Box sx={{ mt: 0, pt: 20, pb:4, bgcolor: 'white' }} className="location">
  <Container sx={{ px: { xs: 2, sm: 4, md: 6, lg: 8 }, mt: -16 }}> {/* Increase negative margin */}
    <Box className='container'>
      <h1>Visit Our Office</h1>
      <h5>Let’s connect enter your details and we’ll be in touch.</h5>
    </Box>

    <Box sx={{ display: 'flex', gap: '40px', flexWrap: 'wrap', justifyContent: 'center' }} mt={4}>
      {/* Phone Card */}
      <Box>
        <a href="tel:+91 954 954 6568" style={{ color: 'black', textDecoration: 'none' }}>
        <Card sx={{ width: '320px',height:'230px',
          transition: 'background-color 0.3s, color 0.3s', 
          '&:hover': {
            backgroundColor: '#30779d', 
            color: 'white',
            '& a': {
              color: 'white !important',
            },
            '& img': { 
              filter: 'invert(1)', 
            }
          },
         }}>
          <CardActionArea>
            <CardMedia 
              component="img"
              image={phone}
              alt="phone contact"
              sx={{ width: 'auto', objectFit: 'contain', display: 'inline', paddingTop: '30px' }}
            />
            <CardContent>
              <Box>
                <h3>+91 954 954 6568</h3>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
        </a>
      </Box>

      {/* Email Card */}
      <Box>
      <a href="mailto:info@dprprop.com" style={{ color: 'black', textDecoration: 'none' }}>
        <Card sx={{ width: '320px',height:'230px',
         transition: 'background-color 0.3s, color 0.3s', 
         '&:hover': {
           backgroundColor: '#30779d', 
           color: 'white',
           '& a': {
             color: 'white !important',
           },
           '& img': { 
             filter: 'invert(1)', 
           }
         },
          }}>
          <CardActionArea>
            <CardMedia 
              component="img"
              image={communication}
              alt="email contact"
              sx={{ width: 'auto', objectFit: 'contain', display: 'inline', paddingTop: '30px' }}
            />
            <CardContent>
              <Box pb={2} pt={2} sx={{ fontWeight: '700' }}>
                info@dprprop.com
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
        </a>
      </Box>

      {/* Address Card */}
      <Box>
        <a href="https://www.google.co.in/maps/dir//Deccan+Progressive+Realty+LLP.,+Plot+no.68,+Senore+Colony,+Veterinary+Colony,+Ambedkar+Nagar,+Hyderabad,+Telangana+500008/@17.4112765,78.4018026,17z/data=!4m17!1m7!3m6!1s0x3bcb973180e2f643:0xc49980f17ee4d523!2sDeccan+Progressive+Realty+LLP.!8m2!3d17.4112765!4d78.4018026!16s%2Fg%2F11s642r7_g!4m8!1m0!1m5!1m1!1s0x3bcb973180e2f643:0xc49980f17ee4d523!2m2!1d78.4018026!2d17.4112765!3e9?entry=ttu&g_ep=EgoyMDI0MTIwMS4xIKXMDSoASAFQAw%3D%3D" target='_blank' rel='noreferrer' style={{ color: 'black', textDecoration: 'none' }}>
        <Card sx={{ width: '320px',height:'230px',
          transition: 'background-color 0.3s, color 0.3s', 
          '&:hover': {
            backgroundColor: '#30779d', 
            color: 'white',
            '& a': {
              color: 'white !important',
            },
            '& img': { 
              filter: 'invert(1)', 
            }
          },
         }}>
          <CardActionArea>
            <CardMedia 
              component="img"
              image={map}
              alt="map location"
              sx={{ width: 'auto', objectFit: 'contain', display: 'inline', paddingTop: '30px' }}
            />
            <CardContent>
              <Box sx={{ fontSize: '12px' }}>
                <h4>Plot no.68, Senore Colony, Veterinary Colony, Ambedkar Nagar, Film Nagar, Hyderabad, Telangana 500008</h4>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
        </a>
      </Box>
    </Box>
  </Container>
</Box>

  )
}

export default Location
