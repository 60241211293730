import React from 'react'
import LayOut from './LayOut'
import LayOutTwo from './LayOutTwo'
import LayOutThree from './LayOutThree'

const GalleryGrid:React.FC = () => {
  return (
    <div>
        <LayOut/>
        {/* <LayOutTwo/> */}
        {/* <LayOutThree/> */}  
    </div>
  )
}

export default GalleryGrid