import React from 'react'
import LoanCalculator from './LoanCalulator'
import { Box, Typography, Divider, Grid, Container, Paper, styled } from '@mui/material';
// import TopHeader from '../../../components/Top/TopHeader';
import { useParams } from 'react-router-dom';
import TopHeader from './TopHeader';


function Calculator() {    
    const { id } = useParams();

    const breadcrumbs = [
        { label: `Project ${id}`, path: `/projects/${id}` }, // Dynamic path with the "id"
      ];
    
  return (
    <div>
          <TopHeader
        value="EMI Calculator"
        breadcrumbs={breadcrumbs}
      />
        <Grid container >
            <Grid item xs={12} >
              <LoanCalculator  />
            </Grid>
            <Grid item xs={12} md={4}>
            </Grid>
          </Grid>
    </div>
  )
}

export default Calculator