import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, Typography, Button, TextField, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';

const steps = ['Upload Resume', 'Basic Information', 'Additional Questions', 'Confirmation'];

const ApplicationPage: React.FC = () => {
    const [activeStep, setActiveStep] = useState(0);
    const { id } = useParams<{ id: string }>();

    const handleNext = () => setActiveStep((prev) => prev + 1);
    const handleBack = () => setActiveStep((prev) => prev - 1);

    return (
        <Paper elevation={3} sx={{ p: 4, mx: 'auto', maxWidth: '800px', mt: 4 }}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
                Application Process for Job ID: {id}
            </Typography>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box mt={4}>
                {activeStep === 0 && (
                    <TextField label="Upload Resume" type="file" fullWidth sx={{ mb: 2 }} />
                )}
                {activeStep === 1 && (
                    <TextField label="Enter Basic Information" fullWidth sx={{ mb: 2 }} />
                )}
                {activeStep === 2 && (
                    <TextField label="Answer Additional Questions" fullWidth sx={{ mb: 2 }} />
                )}
                {activeStep === 3 && (
                    <Typography variant="h6" color="success.main">
                        Thank you for applying!
                    </Typography>
                )}
                <Box mt={2}>
                    <Button disabled={activeStep === 0} onClick={handleBack} size="small">
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        size="small"
                        disabled={activeStep === steps.length - 1}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default ApplicationPage;
