import React from 'react';
import { Box, Typography, Button, List, ListItem, Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { jobsData } from './jobsData';

const JobDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const job = jobsData.find((job) => job.id === id);

    if (!job)
        return (
            <Typography variant="h6" color="error" textAlign="center" mt={4}>
                Job not found
            </Typography>
        );

    return (
        <Paper elevation={3} sx={{ p: 3, mx: 'auto', maxWidth: '800px', mt: 4, borderRadius: '8px' }}>
            {/* Job Title and Company */}
            <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>
                {job.title}
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
                {job.company}
            </Typography>

            {/* Job Details */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="body1" color="text.primary">
                    <strong>Experience Level:</strong> {job.experience}
                </Typography>
                <Typography variant="body1" color="text.primary">
                    <strong>Location:</strong> {job.location}
                </Typography>
            </Box>

            {/* Job Description */}
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3, lineHeight: '1.6' }}>
                {job.description}
            </Typography>

            {/* Responsibilities */}
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                Responsibilities:
            </Typography>
            <List sx={{ pl: 2 }}>
                {job.responsibilities.map((res, index) => (
                    <ListItem key={index} sx={{ fontSize: '0.9rem', color: 'text.secondary' }}>
                        {res}
                    </ListItem>
                ))}
            </List>

            {/* Apply Button */}
            <Button
                variant="contained"
                color="primary"
                sx={{
                    width: '100%',
                    fontSize: '0.875rem',
                    borderRadius: '20px',
                    padding: '12px 20px',
                    boxShadow: '0px 6px 14px #36408D08',
                    mt: 3,
                    '&:hover': {
                        transform: 'scale(1.05)',
                        transition: '0.3s',
                    },
                }}
                onClick={() => navigate(`/careers/apply/${job.id}`)}
            >
                Apply Now
            </Button>
        </Paper>
    );
};

export default JobDetailsPage;
