import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Box, Button, useMediaQuery, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import { CgUserList } from "react-icons/cg";

const InternalDirections: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [isActive, setIsActive] = useState(false);

  const homeSeekarRef = useRef<HTMLButtonElement | null>(null);
  const builderRef = useRef<HTMLButtonElement | null>(null);
  const channelPartnerRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const updateScrollProgress = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = (scrollTop / docHeight) * 100;
    setScrollProgress(scrolled);
    setIsVisible(scrollTop > 100);
    setIsMenuVisible(scrollTop > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollProgress);
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);

  const handleButtonClick = (formType: string) => {
    localStorage.setItem("userType", formType);
    scrollToTop(); // Smooth scroll to top
    setTimeout(() => {
      navigate("/projects"); // Navigate to the Projects page
    }, 800); // Ensure the transition is complete before navigation
  };

  useEffect(() => {
    if (isActive) {
      gsap.fromTo(
        homeSeekarRef.current,
        { y: 20, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.5, delay: 0.1, ease: 'power2.out' }
      );
      gsap.fromTo(
        builderRef.current,
        { y: 20, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.5, delay: 0.3, ease: 'power2.out' }
      );
      gsap.fromTo(
        channelPartnerRef.current,
        { y: 20, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.5, delay: 0.5, ease: 'power2.out' }
      );
    }
  }, [isActive]);

  return (
    <Box>
      {isMenuVisible && (
        <Tooltip title={isActive ? "Close Menu" : "Change User"} placement="left" arrow>
          <IconButton
            onClick={() => setIsActive(!isActive)}
            sx={{
              position: 'fixed',
              bottom: { md: 125, xs: 144 },
              right: { md: 30, xs: 20 },
              width: isSmallScreen ? 38 : 50,
              height: isSmallScreen ? 38 : 50,
              zIndex: 1000,
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              border: '2px solid #30779d',
              borderRadius: '50%',
            }}
          >
            {isActive ? <CloseIcon /> : <CgUserList />}
          </IconButton>
        </Tooltip>
      )}
      {isMenuVisible && isActive && (
        <Box>
          <Tooltip title="Select Home Seeker" placement="left" arrow>
            <Button
              ref={homeSeekarRef}
              sx={{
                clipPath: 'polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 93% 93%, 50% 75%, 0% 75%)',
                position: 'fixed',
                top: { md: 386, xs: 290 },
                right: { md: '-3%', xs: '-17%' },
                transform: 'translate(-50%, 0)',
                width: { md: '215px', xs: '168px' },
                fontSize: { md: 'auto', xs: '12px' },
                height: { md: '70px', xs: '50px' },
                background: 'linear-gradient(135deg, rgba(50, 98, 153, 1) 49%, rgba(125, 185, 232, 0.7) 100%)',
                borderRadius: '8px 15px 30px 60px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '23px',
                gap: '10px',
                textAlign: 'center',
              }}
              onClick={() => handleButtonClick('homeSeeker')}
            >
              Home Seeker
            </Button>
          </Tooltip>
          <Tooltip title="Select Developer/Builder" placement="left" arrow>
            <Button
              ref={builderRef}
              sx={{
                clipPath: 'polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 93% 93%, 50% 75%, 0% 75%)',
                position: 'fixed',
                top: { md: 314, xs: 237 },
                right: { md: '-3%', xs: '-17%' },
                transform: 'translate(-50%, 0)',
                width: { md: '215px', xs: '168px' },
                fontSize: { md: 'auto', xs: '12px' },
                height: { md: '70px', xs: '50px' },
                background: 'linear-gradient(135deg, rgba(50, 98, 153, 1) 49%, rgba(125, 185, 232, 0.7) 100%)',
                borderRadius: '8px 15px 30px 60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '23px',
                gap: '10px',
                textAlign: 'center',
              }}
              onClick={() => handleButtonClick('builder')}
            >
              Developer/Builder
            </Button>
          </Tooltip>
          <Tooltip title="Select Channel Partner" placement="left" arrow>
            <Button
              ref={channelPartnerRef}
              sx={{
                clipPath: 'polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 93% 93%, 50% 75%, 0% 75%)',
                position: 'fixed',
                top: { md: 246, xs: 184 },
                right: { md: '-3%', xs: '-17%' },
                transform: 'translate(-50%, 0)',
                width: { md: '215px', xs: '168px' },
                fontSize: { md: 'auto', xs: '12px' },
                height: { md: '70px', xs: '50px' },
                background: 'linear-gradient(135deg, rgba(50, 98, 153, 1) 49%, rgba(125, 185, 232, 0.7) 100%)',
                borderRadius: '8px 15px 30px 60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '23px',
                gap: '10px',
                textAlign: 'center',
              }}
              onClick={() => handleButtonClick('channelPartner')}
            >
              Channel Partner
            </Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default InternalDirections;
