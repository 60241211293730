import React from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const slugify = (title: string): string => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '') // Remove non-alphanumeric characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .trim();
};

const VerticalTabs: React.FC = () => {
  const navigate = useNavigate();

  const tabItems = [
    { label: "Custom Designed Sales Mechanisms", id: 1 },
    { label: "Brand Consulting & Enhanced Customer Experience", id: 2 },
    { label: "Tech Tools & Automation Systems Implementation", id: 3 },
    { label: "Vendor Management Services", id: 4 },
    { label: "Networking & Visibility Enhancement", id: 5 },
    { label: "Tracking & Reporting Systems", id: 6 },
    { label: "Marketing Campaigns & Strategies", id: 7 },
    { label: "Strategic Alliances & Associations", id: 8 },
    { label: "Management Consulting Services", id: 9 },
  ];

  // Handle the tab navigation
  const handleTabClick = (id: number, title: string) => {
    const slug = slugify(title); // Convert title to a slug
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(`/ourservices/${id}/${slug}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "sticky",
        top: "20px",
        height: "fit-content",
        alignSelf: "start",
      }}
    >
      {/* Tabs */}
      <Tabs orientation="vertical">
        {tabItems.map((item, index) => (
          <Tab
            key={index}
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "600",
                    fontSize: "14px !important",
                  }}
                >
                  {item.label}
                </Typography>
                <ArrowForwardIosIcon sx={{ color: "white", fontSize: "14px" }} />
              </Box>
            }
            onClick={() => handleTabClick(item.id, item.label)}
            sx={{
              backgroundColor: "#30779d",
              borderRadius: "4px",
              marginBottom: "8px",
              padding: "7px",
              textAlign: "left",
              cursor: "pointer",
              fontSize: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              transform: "translateY(2px)",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "#255a73",
                transform: "translateY(0)",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
              },
              "&.Mui-selected": {
                backgroundColor: "#255a73",
                fontWeight: "bold",
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default VerticalTabs;
